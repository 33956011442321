import React from "react"

import "../css/pages/menuiserie.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import {Link} from "gatsby";
import Contact from "../containers/index/contact";

const Menuiserie = () => (
  <Layout>
    <SEO title="Artisan menuisier à Laon dans l'Aisne (02)"
         description={"L'entreprise Athies Batiment basée à Laon intervient pour les travaux de menuiserie dans l'Aisne et dans la Marne. Faites appel à nos artisans menuisiers qualifiés."}/>/>
    <div className={"page menuiserie"}>
        <div className={"header"}>
            <h1 className={"title"}>Artisan menuisier à Laon</h1>
        </div>

        <Section>
            <h2>Pourquoi faire appel à nos menuisiers ?</h2>
            <p>
                Que vous ayez besoin d’une <strong>charpente</strong>, d’un <strong>parquet</strong> pour habiller votre sol, de plusieurs <strong>placards</strong> ou d’un <strong>escalier sur-mesure</strong>, nos artisans menuisiers réaliseront vos projets selon vos besoins et votre budget. Nous réalisons aussi vos travaux de menuiserie extérieure comme l’<strong>installation de portails</strong> et de vos <strong>volets</strong>.
            </p>

            <p>
                Nous en parlons de plus en plus, l'isolation est un point très important pour votre habitation. C'est pour cela qu'il est nécessaire de porter une attention particulière au choix des portes et fenêtres installées. Leur but est de protéger votre maison du froid et de l'humidité.
            </p>

            <p>
                Concernant l'extérieur, il est très important de choisir des volets et un portail de qualité afin de résister aux intempéries mais aussi aux tentatives de cambriolages. En plus de la sécurité fournie par ces matériaux, votre portail et vos volets sont des points importants pour l'esthétique extérieure de votre maison.
            </p>

            <h2>Un accompagnement complet</h2>
            <p>
                La menuiserie est une étape importante dans la construction et la rénovation d’une maison. C’est pour cela que l’entreprise Athies Batiment vous accompagne tout au long de votre projet : de l’étude à la réalisation de vos travaux de menuiserie. Toutes nos réalisations sont conçues pour <strong>répondre à vos besoins</strong> tout en tenant compte des contraintes locales et terrains.
            </p>

            <p className={"subtitle"}>
                Nos artisans menuisiers, <strong>qualifiés et expérimentés</strong>, réaliseront vos travaux de menuiserie selon vos besoins. Nous intervenons principalement dans <strong>l’Aisne (02) et dans la Marne (51)</strong>, dans le secteur proche de Laon, mais nous pouvons aussi nous déplacer dans d'autres secteurs. Pour cela, contactez-nous : <Link to={"/#contact"}>cliquez-ici</Link>.
            </p>
        </Section>

        <Contact/>
    </div>
  </Layout>
)

export default Menuiserie
